import { useTranslation } from "react-i18next";
import { languageOptions } from "../../utils/dataConstants";
import i18n from "../../i18n";

const ServiceComp = ({ Register, Support, Community, UpdateEmail }) => {
  const { t } = useTranslation();

  const browserDetectedLocale = i18n.resolvedLanguage;
  const { country } = languageOptions[browserDetectedLocale];
  const communityURL = country === 'us' ? 'https://us.community.sony.com/s/' : 'https://ca.community.sony.com/s/'

  return (
    <section id="services" className="services section-bg">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="icon-box">
              <div className="box-img">
                <Register />
              </div>
              <div className="h4">{t('login.Why Register')}</div>
              <p className="m-0">
                {t('login.registerProduct')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="icon-box" style={{ cursor: 'pointer' }} onClick={() => window.open("https://www.sony.com/electronics/support", "_blank")}>
              <div className="box-img">
                <Support />
              </div>
              <div className="h4">{t('login.SONY Support')}</div>
              <p className="m-0">
                {t('login.Support Service')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="icon-box" style={{ cursor: 'pointer' }} onClick={() => window.open(communityURL, "_blank")}>
              <div className="box-img">
                <Community />
              </div>
              <div className="h4">{t('login.Our Community')}</div>
              <p className="m-0">
                {t('login.connectCommunity')}
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-3">
            <div className="icon-box" style={{ cursor: 'pointer' }} onClick={() => window.open('https://cloud.email.sel.sony.com/GlobalPriv', '_blank')}>
              <div className="box-img">
                <UpdateEmail />
              </div>
              <div className="h4">{t('login.Update Email Preference')}</div>
              <p className="m-0">
                {t('login.updateInbox')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceComp;

import CanadaFlag from '../assets/img/flags/canada-512.png';
import USAFlag from '../assets/img/flags/unitedStates-512.png';
import CAEN_NEWS from '../appSVG/sweepStakes/CAEN_NEWS.png';
import CAEN_SHOP from '../appSVG/sweepStakes/CAEN_SHOP.png';
import CAFR_NEWS from '../appSVG/sweepStakes/CAFR_NEWS.png';
import CAFR_SHOP from '../appSVG/sweepStakes/CAFR_SHOP.png';
import USEN_NEWS from '../appSVG/sweepStakes/USEN_NEWS.png';
import USEN_SHOP from '../appSVG/sweepStakes/USEN_SHOP.png';
import footerLabel from "../assets/img/footer/Label.jpeg";
import { ReactComponent as SonyIconSvg } from "../appSVG/footer/SonyIcon.svg";
import { ReactComponent as FacebookSvg } from "../appSVG/footer/facebook.svg";
import { ReactComponent as InstagramSvg } from "../appSVG/footer/instagram.svg";
import { ReactComponent as PinterestSvg } from '../appSVG/footer/pinterest.svg';
import { ReactComponent as TwitterSvg } from "../appSVG/footer/twitter.svg";
import { ReactComponent as YoutubeSvg } from '../appSVG/footer/youtube.svg';
import { ReactComponent as CommunitySvg } from '../appSVG/login/community.svg';
import { ReactComponent as RegisterSvg } from '../appSVG/login/register.svg';
import { ReactComponent as SupportSvg } from '../appSVG/login/support.svg';
import { ReactComponent as UpdateEmailSvg } from '../appSVG/login/updateEmail.svg';
import Hero_Login_1 from "../assets/img/slide/Hero_login_1.jpg";
import Hero_Login_2 from "../assets/img/slide/Hero_login_2.jpg";
import Hero_Login_3 from "../assets/img/slide/Hero_login_3.jpg";
import Hero_Login_4 from "../assets/img/slide/Hero_login_4.jpg";
import { createNumberMask } from 'text-mask-addons';


export const domainLanguageCombo = {
  "ca": ['en_CA', 'fr_CA'],
  "com": ['en_US'],
}

export const languageOptions = {
  "en_US": {
    lang: "US",
    country: "us",
    countryCode: "us",
    languageCode: "en-us",
    label: "US English",
    flag: USAFlag,
  },
  // "es_US": {//removed as per business decision
  //   lang: "ES",
  //   country: "us",
  //   countryCode: "us",
  //   languageCode: "es-US",
  //   label: "US Spanish",
  //   flag: USAFlag,
  // },
  "en_CA": {
    lang: "CAN",
    country: "ca",
    countryCode: "ca",
    languageCode: "en-ca",
    label: "CAN English",
    flag: CanadaFlag,
  },
  "fr_CA": {
    lang: "FR",
    country: "ca",
    countryCode: "ca",
    languageCode: "fr-CA",
    label: "CAN French",
    flag: CanadaFlag,
  },
};


export const supportedPOPFileTypes = ["image/gif", "image/jpg", "image/jpeg", "image/png", "application/pdf"]

export const canadaStateList = [
  { places: [{ state: 'Alberta', 'state abbreviation': 'AB' }] },
  { places: [{ state: 'British Columbia', 'state abbreviation': 'BC' }] },
  { places: [{ state: 'Manitoba', 'state abbreviation': 'MB' }] },
  { places: [{ state: 'New Brunswick', 'state abbreviation': 'NB' }] },
  { places: [{ state: 'Newfoundland and Labrador', 'state abbreviation': 'NL' }] },
  { places: [{ state: 'Northwest Territories', 'state abbreviation': 'NT' }] },
  { places: [{ state: 'Nova Scotia', 'state abbreviation': 'NS' }] },
  { places: [{ state: 'Nunavut', 'state abbreviation': 'NU' }] },
  { places: [{ state: 'Ontario', 'state abbreviation': 'ON' }] },
  { places: [{ state: 'Prince Edward Island', 'state abbreviation': 'PE' }] },
  { places: [{ state: 'Quebec', 'state abbreviation': 'QC' }] },
  { places: [{ state: 'Saskatchewan', 'state abbreviation': 'SK' }] },
  { places: [{ state: 'Yukon Territory', 'state abbreviation': 'YT' }] }
]

export const SvgIcons = {
  Register: RegisterSvg,
  Support: SupportSvg,
  Community: CommunitySvg,
  UpdateEmail: UpdateEmailSvg,
  footerLabel: footerLabel,
  SonyIcon: SonyIconSvg,
  Facebook: FacebookSvg,
  Twitter: TwitterSvg,
  Instagram: InstagramSvg,
  Pinterest: PinterestSvg,
  Youtube: YoutubeSvg
}
export const HeroIcons = {
  HeroLogin1: Hero_Login_1,
  HeroLogin2: Hero_Login_2,
  HeroLogin3: Hero_Login_3,
  HeroLogin4: Hero_Login_4
}

export const usCardLinks = ['https://electronics.sony.com/registration-sweepstakes', 'https://electronics.sony.com/']
export const canadaCardLinks = ['https://www.sony.com/en/', 'https://www.thesonyshop.ca/']
export const modelNumberLinks = {
  usModelNumberLink: 'http://esupport.sony.com/info/264/US/',
  caModelNumberLink: 'https://www.sony.ca/en/electronics/support/articles/S1F0264'
}

export const privacyLinks = {
  usPrivacyPolicyLink: 'https://electronics.sony.com/privacy-policy',
  usPrivacyNoticeLink: 'https://electronics.sony.com/privacy-policy',
  caPrivacyPolicyLink: 'https://corporate.sony.ca/view/privacy.htm',
  caPrivacyNoticeLink: 'https://corporate.sony.ca/view/privacy.htm',
}

export const currencyMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 8, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
})

export const sweepStakes = {
  en_US: {
    news: USEN_NEWS,
    shop: USEN_SHOP
  },
  en_CA: {
    news: CAEN_NEWS,
    shop: CAEN_SHOP
  },
  fr_CA: {
    news: CAFR_NEWS,
    shop: CAFR_SHOP
  },
}

export const refPastDate = new Date(new Date().setFullYear(new Date().getFullYear() - 20));

export const defaultDynFormParams = {
  mandate_onlineinstore: true,
  mandate_optinmarketing: false,
  mandate_pricepaid: false,
  mandate_proofofpurchase: false,
  mandate_retailerassociate: false,
  mandate_retailername: true,
  mandate_serialnumber: false,
}

export const purchaseType = {
  inStore: 'in-store',
  online: 'online'
}

export const campaignLinks = {
  en_US: 'bit.ly/Sony1YRUS',
  en_CA: 'bit.ly/Sony1YRCA',
  fr_CA: 'bit.ly/Sony1YRFR'
}
import {
  MDCIM_OAUTH_URL,
  app_client_id,
  redirect_uri,
  registria_redirect_url
} from "../config";
import { deleteToken, doRegistriaCall } from "../service/ProductService";
import { deleteFromSessionStorage } from "../service/SessionStorageService";
import { accessToken, loggedInUser, refreshToken, showConfirmationPage } from "../utils/Constants";

const select = (el, all = false) => {
  el = el.trim();
  if (all) {
    return [...document.querySelectorAll(el)];
  } else {
    return document.querySelector(el);
  }
};

export const handleCarouselItems = () => {
  let heroCarouselIndicators = select("#hero-carousel-indicators");
  let heroCarouselItems = select("#heroCarousel .carousel-item", true);

  heroCarouselItems.forEach((item, index) => {
    index === 0
      ? (heroCarouselIndicators.innerHTML +=
        "<li data-bs-target='#heroCarousel' data-bs-slide-to='" +
        index +
        "' class='active'></li>")
      : (heroCarouselIndicators.innerHTML +=
        "<li data-bs-target='#heroCarousel' data-bs-slide-to='" +
        index +
        "'></li>");
  });
};

export const getRandomString = (length) => {
  const characters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_";
  return Array.from(
    { length },
    () => characters[Math.floor(Math.random() * characters.length)]
  ).join("");
};

export const handleSignIn = async (event, origin, locale) => {
  //capturing event for Google analytics
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "intercative click",
    buttonText: event?.target?.textContent,
  });
  event ? event.preventDefault() : null;
  let redirect_uri = "";
  if (!locale) {
    redirect_uri = `${window.location.origin}/callback`;
  } else if (!origin) {
    redirect_uri = `${window.location.origin}/${locale}/callback`;
  } else {
    redirect_uri = `${origin}/${locale}/callback`;
  }
  const queryParams = `/signin?client_id=${app_client_id}&scope=openid+users&redirect_uri=${redirect_uri}&state=${getRandomString(
    40
  )}&nonce=${getRandomString(40)}`;
  let oauthUrl = MDCIM_OAUTH_URL + queryParams;
  window.location.href = oauthUrl;
};

export const handleSignUp = async (event, origin, locale) => {
  //capturing event for Google analytics
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "interactive click",
    buttonText: event?.target?.textContent,
  });
  event?.preventDefault();
  let redirect_uri = "";
  if (!locale) {
    redirect_uri = `${window.location.origin}/callback`;
  } else if (!origin) {
    redirect_uri = `${window.location.origin}/${locale}/callback`;
  } else {
    redirect_uri = `${origin}/${locale}/callback`;
  }
  const queryParams = `/create_account/agreement?client_id=${app_client_id}&scope=openid+users&redirect_uri=${redirect_uri}&state=${getRandomString(
    40
  )}&nonce=${getRandomString(40)}`;
  let oauthUrl = MDCIM_OAUTH_URL + queryParams;
  window.location.href = oauthUrl;
};

export const handleLogout = async (locale, onlyRedirect) => {
  if (!onlyRedirect) {
    await deleteToken();
  }

  deleteFromSessionStorage(accessToken);
  deleteFromSessionStorage(refreshToken);
  deleteFromSessionStorage(loggedInUser);

  let signout_uri = "";
  if (!locale) {
    signout_uri = `${window.location.origin}/callback`;
  } else {
    signout_uri = `${window.location.origin}/${locale}/callback`;
  }
  const queryParams = `/delete_session?client_id=${app_client_id}&scope=openid+users&redirect_uri=${signout_uri}&state=${getRandomString(
    40
  )}`;
  let logoutUrl = MDCIM_OAUTH_URL + queryParams;
  window.location.href = logoutUrl;
};

export const handleBazarVoice = async (bvToken, locale) => {
  const bazarVoiceURL = `https://www.sony.com/reviews/submission?${bvToken}`;
  const bazarVoiceFrURL = `https://www.sony.fr/reviews/submission?${bvToken}`;
  window.open(locale === 'fr-ca' ? bazarVoiceFrURL : bazarVoiceURL, "_blank");
};

export const openMyProfile = () => {
  const myProfileURL = `${MDCIM_OAUTH_URL}/account/menu?client_id=${app_client_id}&scope=openid+users&redirect_uri=${redirect_uri}&state=${getRandomString(
    40
  )}&nonce=${getRandomString(40)}`;
  window.open(myProfileURL, "_blank");
};

export const removeHtmlTags = (text) => {
  const regex = /(<([^>]+)>)/gi;
  const parsedText = text.replace(regex, "");
  return parsedText;
};

export function isJSON(str) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

export const currentDomain = () => {
  const url = window.location.host;
  return url.includes(".ca") ? "ca" : url.includes(".com") ? "com" : undefined;
};

export const convertLangToParams = (lang) => {
  return lang ? lang.toLowerCase().replace("_", "-") : null;
};

export const convertParamsToLang = (params) => {
  return params
    ? (params.slice(0, 3) + params.slice(3).toUpperCase()).replace("-", "_")
    : null;
};

export const handleQualtrics = async (modelName) => {
  const qualtricsURL = `https://sony.qualtrics.com/jfe/form/SV_1H5wIOwcGyb9Uzz?mdl=${modelName}`;
  window.open(qualtricsURL, "_blank");
};

const supportUrl = {
  'en-us': 'https://www.sony.com/en_us/SCA/contacts/overview.html',
  'en-ca': 'https://corporate.sony.ca/view/contact_info.htm',
  'fr-ca': 'http://fr.corporate.sony.ca/view/Nos_coordonnees.htm'
}

export const handleContactSupport = async (locale) => {
  let contactSupport = 'https://www.sony.com/en_us/SCA/contacts/overview.html';
  if (supportUrl[locale]) {
    contactSupport = supportUrl[locale];
  }
  window.open(contactSupport, "_blank");
};


export const scrollToTop = (elementId) => {
  if (elementId?.length) {
    document.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
    return
  }
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export const removeHash = (event) => {
  event.preventDefault();
}

export const fileSizeRestriction = (file) => {
  const fileSize = Math.round((file?.size / (1024 * 1024)))
  return fileSize >= 10 //10MB pop upload limit
}

export const checkFileType = (file) => {
  return ["image/gif", "image/jpg", "image/jpeg", "image/png", "application/pdf"].includes(file?.type);
}

export const fileExtension = (fileName) => fileName?.split('.')?.pop() || '';

export const getFileName = (str, type = 'model_name', stringLength = 15) => {
  if (type === 'pop') {
    if (str?.length > 25) {
      return str.substr(0, stringLength) + '...' + str.substr(-11)
    }
  } else if (type === 'model_name') {
    stringLength = stringLength || 40
    if (str?.length > 40) {
      return str.substr(0, stringLength) + '...'
    }
  }
  return str || ''
}

export async function handleRegistria(token, registrationId, { serial_number, sku, purchase_date, delivery_date }, locale, logged_in, callRegistria = true) {
  if (callRegistria && Boolean(token)) {
    try {
      await doRegistriaCall(token);
    } catch (e) {
      console.log("Call failed")
    }
  }
  const url_locale = convertLangToParams(locale);
  const sendParams = new URLSearchParams();
  const currentParams = new URLSearchParams(window.location.search.toLowerCase());
  currentParams.get("serial") ? sendParams.set("serial", currentParams.get("serial")) : serial_number ? sendParams.set("serial", serial_number) : "";
  currentParams.get("model") ? sendParams.set("model", currentParams.get("model")) : sendParams.set("model", sku);
  currentParams.get("r") ? sendParams.set("r", currentParams.get("r")) : sendParams.set("r", "web");
  currentParams.get("rpid") ? sendParams.set("r", currentParams.get("rpid")) : sendParams.set("rpid", "PROMO");
  sendParams.set("locale", url_locale);  // which locale to send? current or the one from query params
  sendParams.set("extid", registrationId);
  sendParams.set("subsource", "external");
  sendParams.set("dop", purchase_date);
  delivery_date && sendParams.set("dod", delivery_date);
  let url = `${window.location.origin}/${url_locale}`
  if (logged_in) {
    url = url + "/registered_products";
  } else {
    url = url + "/registration_confirmation";
    sessionStorage.setItem(showConfirmationPage, 'true');
  }
  sendParams.set("rt", encodeURIComponent(url));
  window.location.replace(`${registria_redirect_url}?${sendParams.toString()}`);
}

export const isFutureDate = (date) => {
  return new Date(date) > new Date()
}

export const RegForm = {
  UF: "UF",
  YF: "YF",
  PF: "PF",
}

export const isEmptyObj = (obj) => {
  if (obj == null) {
    return false;
  }

  if (typeof obj !== 'object') {
    return false;
  }

  return Object.keys(obj).length === 0;
}

export const convertDate = (date) => {
  date = new Date(date)
  var yyyy = date.getFullYear().toString();
  var mm = (date.getMonth() + 1).toString();
  var dd = date.getDate().toString();

  var mmChars = mm.split('');
  var ddChars = dd.split('');

  return yyyy + '-' + (mmChars[1] ? mm : "0" + mmChars[0]) + '-' + (ddChars[1] ? dd : "0" + ddChars[0]);
}

export const withoutTime = (date) => {
  const wDate = new Date(date)
  wDate.setHours(0, 0, 0, 0);
  return wDate
}

export const refFutureDate = (selectPurchaseDate) => {
  let date = new Date(selectPurchaseDate);
  date.setMonth(date.getMonth() + 3);
  return new Date(date);
}
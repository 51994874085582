import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Hero_desktop from '../../assets/img/slide/Hero_desktop.png';
import Hero_mobile from '../../assets/img/slide/Hero_mobile.png';
import Hero_2_desktop from '../../assets/img/slide/Hero_2_desktop.png';
import Hero_mobile_2 from '../../assets/img/slide/Hero_mobile_2.png';
import Hero_3_desktop from '../../assets/img/slide/Hero_3_desktop.png';
import Hero_mobile_3 from '../../assets/img/slide/Hero_mobile_3.png';
import { handleCarouselItems } from "../../utils/utils";
import { ReactComponent as LocateSvg } from "../../assets/img/1.svg"
import { ReactComponent as EmailSvg } from "../../assets/img/2.svg"
import { ReactComponent as MusicSvg } from "../../assets/img/3.svg"
import { ReactComponent as MoneySvg } from "../../assets/img/4.svg"
import { ReactComponent as OnePlaceSvg } from "../../assets/img/5.svg"
import i18n from "../../i18n";
import { languageOptions } from "../../utils/dataConstants";

export const Banner = () => {
	const { t } = useTranslation();
	const browserDetectedLocale = i18n.resolvedLanguage;
	const { country } = languageOptions[browserDetectedLocale];
	const [sliderInfo, setSliderInfo] = useState(null);

	const CABanner = [
		{ key: 0, icon: <LocateSvg />, info: t('banner.Easier access to support content') },
		{ key: 1, icon: <EmailSvg />, info: t('banner.Product updates by email') },
		{ key: 2, icon: <OnePlaceSvg />, info: t('banner.Your registered products in one place') },
	]
	const USBanner = [
		{ key: 0, icon: <LocateSvg />, info: t('banner.Easier access to support content') },
		{ key: 1, icon: <EmailSvg />, info: t('banner.Product updates by email') },
		{ key: 2, icon: <MusicSvg />, info: t('banner.freeAmazonMusic') },
		{ key: 3, icon: <MoneySvg />, info: t('banner.prize') },
	]
	useEffect(() => {
		handleCarouselItems();
		setSliderInfo(country === 'ca' ? CABanner : USBanner);
	}, []);

	return (
		<section id="hero">
			<div className="container">
				<div className="col-md-7 col-12 hero-container__info align-items-md-start d-flex flex-column">
					<h2 >{country === "ca" ? t('banner.Register your SONY product') : t('banner.sonyProductInOnePlace')}</h2>
					<div className="d-flex flex-column">
						{
							sliderInfo?.map((info) => (
								<div className="d-flex" key={info["key"]}>
									<div className="slider-info">
										{info["icon"]}
									</div>
									<span>
										{info["info"]}
									</span>
								</div>
							))
						}
					</div>
				</div>
			</div>
			<div id="carouselExampleIndicators" data-bs-interval="12000" data-bs-ride="carousel" className="hero-container d-md-flex ipad">
				<div className="col-md-5 col-12 block__backing">
				</div>
				<div id="heroCarousel" data-bs-interval="12000" data-bs-ride="carousel" className="carousel slide ms-auto carousel-fade col-md-7">
					<ol className="carousel-indicators" id="hero-carousel-indicators"></ol>
					<div className="carousel-inner" role="listbox">

						<div className="carousel-item active">
							<div className="carousel-container">
								<div className="carousel-content d-md-block d-none" style={{ backgroundImage: `url(${Hero_desktop})` }}>
								</div>
								<div className="carousel-content d-block d-md-none" style={{ backgroundImage: `url(${Hero_mobile})` }}>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<div className="carousel-container">
								<div className="carousel-content d-md-block d-none" style={{ backgroundImage: `url(${Hero_2_desktop})` }}>
								</div>
								<div className="carousel-content d-block d-md-none" style={{ backgroundImage: `url(${Hero_mobile_2})` }}>
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<div className="carousel-container">
								<div className="carousel-content d-md-block d-none" style={{ backgroundImage: `url(${Hero_3_desktop})` }}>
								</div>
								<div className="carousel-content d-block d-md-none" style={{ backgroundImage: `url(${Hero_mobile_3})` }}>
								</div>
							</div>
						</div>
						<div className="bg"></div>
					</div>
				</div>
			</div>
		</section>
	);
}

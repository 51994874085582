import axios from "axios";
import { footer_url, urls, S3_API } from "../config";
import { AxiosInstancePrivate, AxiosInstancePublic } from "../utils/AxiosInstance";

const privateAxios = AxiosInstancePrivate();
const publicAxios = AxiosInstancePublic();

// Public Services

export const registerProduct = async (payload) => {
  const response = await publicAxios.post(urls.public.registerProduct, payload);
  return response?.data;
};

export const getRetailerList = async (selectedPurchaseType, country) => {
  const params = {
    type: selectedPurchaseType,
    country
  }
  const response = await publicAxios.get(
    urls.public.retailers, { params }
  );
  return response?.data;
};

export const doExternalCalls = async (token) => {
  publicAxios.post(`${urls.public.externalCall}`, { token })
}

export const doRegistriaCall = async (token) => {
  return await publicAxios.post(`${urls.public.registriaCall}`, { token })
}

export const getProductList = async (keyword, locale) => {
  const params = {
    product_keyword: keyword,
    product_locale: locale
  }
  const response = await publicAxios.get(
    urls.public.products, { params }
  );
  return response?.data;
};

export const getAddress = async (zipcode) => {
  const response = await axios.get(`https://api.zippopotam.us/us/${zipcode}`);
  return response?.data;
};


export const uploadFileToS3 = async (formData) => {
  const s3Data = await axios({
    method: "POST", url: `${S3_API}${urls.public.s3bucketurl}`,
    data: formData, headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'multipart/form-data' },
    timeout: 180000
  });
  return s3Data;
};

export const getS3PreSignedUrl = async (payload) => {
  const response = await privateAxios.post(`${urls.private.s3PreSignedUrl}`, payload);
  return response?.data;
};


export const getS3Object = async (payload) => {
  const response = await privateAxios.post(`${urls.private.getS3Object}`, payload);
  return response?.data;
};


export const getFooter = async (locale) => {
  const response = await axios.get(`${footer_url}&locale=${locale}`);
  return response?.data;
}

export const codeExchange = async (authCodeBody, authCode, locale) => {
  const url = locale ? `${urls.public.ouath2}?locale=${locale}` : `${urls.public.ouath2}`
  const response = await publicAxios.post(
    url,
    authCodeBody,
    {
      headers: {
        code: authCode
      },
    }
  );
  return response?.data;
};

export const deleteToken = async () => {
  const response = await privateAxios.post(`${urls.public.ouath2}`);
  return response?.data;
};

// Private Services

// export const getRegisteredProductsTest = async (locale) => {
//   const userInfo = {
//     "id": "998989",
//     "sub": "9980000",
//     "verifiedEmail": "asdasd@fdg.com",
//     "guid": "98765",
//     "email": "asdasd@fdg.com",//change to local db email
//     "legal_country": "us",
//     "language": "en_US",
//     "date_of_birth": "1995-03-08",
//     "last_name": "Karajagi",
//     "first_name": "Roopa",
//     "postal_code": "06612",
//     "address_1": "name",
//     "address_2": "2-3",
//     "address_3": "park city garden 3401",
//     "city": "eatern city",
//     "country_subdivision": "CT",
//     "associations": [
//       {
//         "client_id": "dcc0b327-fea4-4382-a790-d3c7c4fe12db"
//       }
//     ],
//     "dcim_associations": [
//       {
//         "dcim_id": "55a9c1d1-886a-4693-9ced-df37d6c437d9"
//       }
//     ],
//     "phones": [
//       {
//         "is_verified": true,
//         "phone": "9449798989",
//         "country_code": "12"
//       }
//     ]
//   };
//   const response = await publicAxios.post(
//     `${urls.private.registered_products}?product_locale=${locale}`,
//     userInfo
//   );
//   return response?.data;
// };

export const getRegisteredProducts = async (locale) => {
  const response = await privateAxios.get(
    `${urls.private.registered_products}?product_locale=${locale}`
  );
  return response?.data;
};

export const deleteProduct = async (reg_id, delete_reason) => {
  const response = await privateAxios.post(`${urls.private.delete_product}`, {
    registration_id: reg_id,
    delete_reason: delete_reason,
  });
  return response?.data;
};

export const uploadProof = async (
  payload
) => {
  const response = await privateAxios.post(`${urls.private.upload_proof}`, payload);
  return response?.data;
};

// export const getS3PreSignedUrl = async (
//   payload
// ) => {
//   const response = await privateAxios.post(`${urls.private.s3PreSignedUrl}`, payload);
//   return response?.data;
// };


// export const publicUploadProof = async (
//   payload
// ) => {
//   const response = await publicAxios.post(`${urls.private.upload_proof}`, {
//     "userInfo": {
//       "id": "998989",
//       "sub": "9980000",
//       "verifiedEmail": "asdasd@fdg.com",
//       "guid": "98765",
//       "email": "asdasd@fdg.com",//change to local db email
//       "legal_country": "us",
//       "language": "en_US",
//       "date_of_birth": "1995-03-08",
//       "last_name": "Karajagi",
//       "first_name": "Roopa",
//       "postal_code": "06612",
//       "address_1": "name",
//       "address_2": "2-3",
//       "address_3": "park city garden 3401",
//       "city": "eatern city",
//       "country_subdivision": "CT",
//       "associations": [
//         {
//           "client_id": "dcc0b327-fea4-4382-a790-d3c7c4fe12db"
//         }
//       ],
//       "dcim_associations": [
//         {
//           "dcim_id": "55a9c1d1-886a-4693-9ced-df37d6c437d9"
//         }
//       ],
//       "phones": [
//         {
//           "is_verified": true,
//           "phone": "9449798989",
//           "country_code": "12"
//         }
//       ]
//     }, ...payload
//   });
//   return response?.data;
// };

export const deleteProof = async (registration_id) => {
  const response = await privateAxios.post(`${urls.private.delete_proof}`, {
    registration_id,
  });
  return response?.data;
};

// export const deleteProofTest = async (registration_id) => {
//   const userInfo = {
//     "id": "98765",
//     "sub": "9980000",
//     "verifiedEmail": "asdasd@fdg.com",
//     "guid": "98765",
//     "email": "asdasd@fdg.com", //change to local db email
//     "legal_country": "us",
//     "language": "en_US",
//     "date_of_birth": "1995-03-08",
//     "last_name": "Karajagi",
//     "first_name": "Roopa",
//     "postal_code": "06612",
//     "address_1": "name",
//     "address_2": "2-3",
//     "address_3": "park city garden 3401",
//     "city": "eatern city",
//     "country_subdivision": "CT",
//     "associations": [
//       {
//         "client_id": "dcc0b327-fea4-4382-a790-d3c7c4fe12db"
//       }
//     ],
//     "dcim_associations": [
//       {
//         "dcim_id": "55a9c1d1-886a-4693-9ced-df37d6c437d9"
//       }
//     ],
//     "phones": [
//       {
//         "is_verified": true,
//         "phone": "9449798989",
//         "country_code": "12"
//       }
//     ]
//   };
//   const response = await privateAxios.post(`${urls.private.delete_proof}`, {
//     registration_id,
//     userInfo
//   });
//   return response?.data;
// };

export const registerProductOfUser = async (payload) => {
  const response = await privateAxios.post(
    `${urls.private.registerProduct}`,
    payload
  );
  return response?.data;
};

export const getProtectionPlan = async (registration_id) => {
  const response = await privateAxios.post(
    `${urls.private.protection_plan}`,
    { registration_id }
  );
  return response?.data;
};

export const getMandatoryFields = async (productId, countryCode, purchaseDate, deliveryDate, isUpdate = false, campaignid = '') => {
  const params = {
    product_id: productId,
    countryCode: countryCode,
    purchaseDate: purchaseDate,
    deliveryDate: deliveryDate,
    isUpdate,
    campaign_id: campaignid
  }
  const response = await privateAxios.get(`${urls.public.campaignId}`, { params });
  return response?.data;
};


// export const registerProductOfUserTest = async (payload) => {
//   const mdcimUser = {
//     "id": "98765",
//     "sub": "9980000",
//     "verifiedEmail": "asdasd@fdg.com",
//     "guid": "98765",
//     "email": "asdasd@fdg.com", //change to local db email
//     "legal_country": "us",
//     "language": "en_US",
//     "date_of_birth": "1995-03-08",
//     "last_name": "Karajagi",
//     "first_name": "Roopa",
//     "postal_code": "06612",
//     "address_1": "name",
//     "address_2": "2-3",
//     "address_3": "park city garden 3401",
//     "city": "eatern city",
//     "country_subdivision": "CT",
//     "associations": [
//       {
//         "client_id": "dcc0b327-fea4-4382-a790-d3c7c4fe12db"
//       }
//     ],
//     "dcim_associations": [
//       {
//         "dcim_id": "55a9c1d1-886a-4693-9ced-df37d6c437d9"
//       }
//     ],
//     "phones": [
//       {
//         "is_verified": true,
//         "phone": "9449798989",
//         "country_code": "12"
//       }
//     ]
//   };
//   const product = {
//     ...payload,
//     purchaseType: "online",
//   }
//   const response = await privateAxios.post(
//     `${urls.private.registerProduct}`,
//     { mdcimUser, product }
//   );
//   return response?.data;
// };
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import {
  deleteProduct,
  doExternalCalls,
  doRegistriaCall,
  getRegisteredProducts,
} from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import {
  SHOW_ERROR_ALERT,
  SHOW_SUCCESS_ALERT,
} from "../../store/reducer/AlertReducer";
import { canadaCardLinks, languageOptions, sweepStakes, usCardLinks } from "../../utils/dataConstants";
import { scrollToTop } from "../../utils/utils";
import { Loading } from "../login/Loading";
import "./MyProducts.css";
import ProductsPagination from "./pagination/ProductPagination";
import { MyProductModal } from './Modal/ProductModals'
import { IdleTimeTracker, Product, addOverFlowHidden, removeOverFlowHidden } from "../../components/components";

export const MyProducts = () => {
  const { t } = useTranslation();
  const { setShowAlert } = useContext(AppContext);
  const [products, setProducts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFistProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = products.slice(
    indexOfFistProduct,
    indexOfLastProduct
  );
  const lastPageNum = Math.ceil(products?.length / itemsPerPage);

  const {
    loggedInUser: { first_name },
  } = useContext(AppContext);

  const { resolvedLanguage: locale } = i18n;

  useEffect(() => {
    getRegisteredProductList();
  }, []);

  const getRegisteredProductList = async (updatedData = {}) => {
    setShowLoader(true);
    try {
      const data = await getRegisteredProducts(locale);
      if (data) {
        const { registrationid, popNotificationType, popNotificationMessage } = updatedData
        if (Boolean(registrationid)) {
          const refreshedData = [...data]?.map(element => {
            if (element?.registrationid === registrationid) {
              element.popAlerts = {
                popNotificationType,
                popNotificationMessage
              }
            }
            return element
          })
          setProducts(refreshedData)
        } else {
          setProducts(data);
        }
        const regProducts = data.map((product) => ({
          productSku: product?.skuname,
          registerDate: product?.registrationdate,
          purchaseDate: product?.purchasedate,
          purchaseAt: product?.retailername
        }));

        //capturing event for Google analytics
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "my-product-info",
          productCount: data?.length,
          username: first_name,
          registered_products: regProducts
        });
      } else {
        setProducts([]);
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.error"),
        });
      }
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.productError') });
    } finally {
      setShowLoader(false);
    }
  }

  const removeProduct = async (regId, deleteReason) => {
    try {
      const data = await deleteProduct(regId, deleteReason);
      if (data.deletionStatus === "SUCCESS") {
        const newState = products.filter(
          (product) => product?.registrationid !== regId
        );
        setShowAlert({
          type: SHOW_SUCCESS_ALERT,
          message: t("alertMessages.productDelete"),
        });
        setProducts(newState);
        if (currentPage === lastPageNum) {
          if (currentPage === 1) {
            setCurrentPage(1);
          } else {
            if (currentProducts?.length <= 1) {
              setCurrentPage(currentPage - 1);
            }
          }
        }
        doExternalCalls(data?.token)
        await doRegistriaCall(data?.token)
      } else {
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.catch"),
        });
      }
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: error.message });
    }
  };

  const onCloseModal = () => {
    removeOverFlowHidden();
    setOpenModal(false);
  };

  const handleShowModal = (event) => {
    // capturing event for Google Analytics
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "intercative click",
      buttonText: event?.target?.textContent,
    });
    addOverFlowHidden();
    setOpenModal(true);
  };

  const browserDetectedLocale = i18n.resolvedLanguage;
  const { country } = languageOptions[browserDetectedLocale];
  const cardLinks = country === 'us' ? usCardLinks : canadaCardLinks

  return (
    <main id="main">
      <section className="my-products">
        <div className="container">
          <div className="row content">
            {/* Right Side */}
            <div className="col-lg-8 d-flex flex-column">
              <div className="d-flex flex-column flex-md-row justify-content-between welcome-block">
                <div className="d-flex mb-3 mb-md-0 flex-column justify-content-between">
                  <div className="my-products__welcome-title">
                    {t("myProducts.Welcome")}, {first_name}!
                  </div>
                  <h2 className="m-0 my-products__title">
                    {t("myProducts.My Products")}
                  </h2>
                </div>
                {/*  Setting 30 minutes time out period. */}
                <IdleTimeTracker idleTimeOut="1800000" />
                <button
                  className="btn btn-primary accent-button register-add-button"
                  type="button"
                  onClick={(e) => handleShowModal(e)}
                >
                  <i
                    aria-hidden="true"
                    className="spri-icon-Plus"
                  ></i>
                  {t("myProducts.register")}
                </button>
                {/* Modal */}
                {openModal && (
                  <MyProductModal
                    openModal={openModal}
                    closeModal={onCloseModal}
                    getRegProductList={getRegisteredProductList}
                  />
                )}
              </div>
              <div className="col-lg-12 d-flex flex-column product-container">
                <Loading show={showLoader} />
                {!!currentProducts?.length
                  ? currentProducts?.map((product, i) => (
                    <Product
                      key={product?.registrationid + i + Math.random()}
                      data={product}
                      deleteProduct={(regId, deleteReason) => removeProduct(regId, deleteReason)}
                      getUpdatedProductsList={(updatedData) => getRegisteredProductList(updatedData)}
                    />
                  ))
                  : null}
                {products?.length > 3 ? (
                  <ProductsPagination
                    itemsPerPage={itemsPerPage}
                    totalItems={products?.length}
                    currentPage={currentPage}
                    paginate={(pageNum) => { setCurrentPage(pageNum); scrollToTop(); }}
                  />
                ) : null}
              </div>
            </div>
            {/* Left Side */}
            <div className="col-lg-4 pt-4 pt-lg-0 d-flex flex-lg-column justify-content-around justify-content-lg-start">
              <div className="bg-block align-self-end me-lg-0 me-2" style={{ cursor: 'pointer' }}
                onClick={() => window.open(cardLinks[0], "_blank")}>
                <img src={sweepStakes[browserDetectedLocale]['news']} alt="Chance to Win Sony Sweepstakes" />
              </div>
              <div className="bg-block align-self-end ms-lg-0 ms-2" style={{ cursor: 'pointer' }}
                onClick={() => window.open(cardLinks[1], "_blank")}>
                <img src={sweepStakes[browserDetectedLocale]['shop']} alt="Shop Sony Products" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
